import * as _lodash2 from "lodash.throttle";

var _lodash = "default" in _lodash2 ? _lodash2.default : _lodash2;

var exports = {};
const throttle = _lodash;

function emitSocketProgress(uploader, progressData, file) {
  const {
    progress,
    bytesUploaded,
    bytesTotal
  } = progressData;

  if (progress) {
    uploader.uppy.log(`Upload progress: ${progress}`);
    uploader.uppy.emit("upload-progress", file, {
      uploader,
      bytesUploaded,
      bytesTotal
    });
  }
}

exports = throttle(emitSocketProgress, 300, {
  leading: true,
  trailing: true
});
export default exports;